import { Navbar, NavbarBrand } from "reactstrap";

const Header = () => {
    return (
        <Navbar dark sticky='top'expand='md'>
            <NavbarBrand className='ms-5' href='/'>
                <h1 className='mt-2'>D Lemmi Bows</h1>
            </NavbarBrand>
        </Navbar>
    );
}

export default Header;
