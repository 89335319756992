import { Row, Col } from "reactstrap";

const Footer = () => {
    return (
            <footer className="site-footer">
                <Row className="footer-row">
                    <Col xs={{ size: 4, offset: 1}} sm='2'>
                        <h5>Address</h5>
                        <p className='mt-2'>
                            420 Bell Street{<br/>}
                            Edmonds, WA 98020{<br/>}
                            by appointment only
                        </p>
                    </Col>
                    <Col xs='6' sm='3' className='text-center'>
                        <h5>Social</h5>
                        <a className='btn btn-social-icon btn-instagram' href='https://www.instagram.com'>
                            <i className='fa fa-instagram' />
                        </a>{' '}
                        <a className='btn btn-social-icon btn-facebook' href='https://www.facebook.com'>
                            <i className='fa fa-facebook' />
                        </a>{' '}
                        <a className='btn btn-social-icon btn-twitter' href='https://www.twitter.com'>
                            <i className='fa fa-twitter' />
                        </a>{' '}
                        <a className='btn btn-social-icon btn-google' href='https://www.youtube.com'>
                            <i className='fa fa-youtube' />
                        </a>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <a role='button' className='btn btn-link' href='tel:+14252245689'>
                            <i className='fa fa-phone' /> 1-425-224-5689
                        </a><br />
                        <a role='button' className='btn btn-link' href='mailto:debbie@dlemmibows.com'>
                            <i className='fa fa-envelope-o' /> debbie@dlemmibows.com
                        </a>
                    </Col>
                </Row>
            </footer>
    );
}

export default Footer;
