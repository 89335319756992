const ComingSoon = () => {
    return (
        <div className="coming-soon">
            <h2>Website Coming Soon</h2>
            <br />
            <p>
                debbie@dlemmibows.com<br />
                <a role='button' className='btn btn-link' href='tel:+14252245689'>
                <i className='fa fa-phone' /> 1-425-224-5689</a><br /><br />
                420 Bell Street<br />
                Edmonds, WA 98020<br />
            </p><br />
            <h3>by appointment only</h3>

        </div>
    );
}

export default ComingSoon;
